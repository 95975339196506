/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMutationOptions } from 'infra'
import { useMutation } from 'react-query'

import { actionService } from '../pagarMeService'

export function usePostTestConnection(options?: IMutationOptions) {
  const { mutate, isLoading, isSuccess, isError } = useMutation<
    any,
    Error,
    any
  >({
    mutationFn: async () => {
      return await actionService.postTestConnection()
    },
    retry: false,
    onError: (error, arg2, arg3) => {
      if (options?.onError) {
        options.onError(error.message, arg2, arg3)
      }
    },
    onSuccess: (data, arg2, ar3) => {
      if (options?.onSuccess) {
        options.onSuccess(data, arg2, ar3)
      }
    },
  })

  return {
    onTestConnection: () => mutate({}),
    isLoading,
    isSuccess,
    isError,
  }
}
