import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import {
  BreadcrumbItem,
  Breadcrumbs
} from 'components/Breadcrumbs/Breadcrumbs';
import { CardChooseConfig } from 'components/CardChooseConfig';
import { CardIntegration } from 'components/ContainerCollapse/ContainerCollapse';
import {
  ENUM_TYPES_SCHEDULER_CONFIG,
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes
} from 'components/ContainerCollapse/meta/schedulerConfigTypes';
import {
  IRpaConfigResponse,
  Scheduler
} from 'domains/RpaConfig/rpaConfigTypes';
import { useGetConfigRpa } from 'domains/RpaConfig/useCases';
import { useParams } from 'react-router-dom';

type CardSelectedType = IRpaConfigResponse['config']['outbounds'][0];

export const Configuracoes = () => {
  const { rpaId } = useParams();

  const { data: rpaConfig } = useGetConfigRpa({
    rpaId,
    enabled: true
  });

  const [cardSelected, setCardSelected] = useState<CardSelectedType>(
    {} as CardSelectedType
  );

  const [schedulerSelected, setSchedulerSelected] = useState<Scheduler>(
    {} as Scheduler
  );

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const showDetailsConfig = useMemo(() => {
    return !!cardSelected?.name || !!schedulerSelected?.name;
  }, [cardSelected, schedulerSelected]);

  const handleSelectCard = useCallback(
    (type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES) => {
      let findConfig;
      let schedulerConfig;

      if (type === ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER) {
        schedulerConfig = rpaConfig?.config?.scheduler;
        schedulerConfig.name = 'Agendador';
      } else {
        findConfig = rpaConfig?.config?.outbounds?.find(
          item => item.name === type
        );
      }

      const informationsConfig = schedulerConfigTypes.find(
        config => config.type === type
      );

      setBreadcrumbs([
        {
          name: 'Configurações',
          value: 'config'
        },
        {
          name: informationsConfig.title,
          value: schedulerConfig === undefined ? findConfig.name : 'Agendador'
        }
      ]);

      if (schedulerConfig !== undefined) {
        setSchedulerSelected(schedulerConfig);
      }

      if (findConfig) {
        setCardSelected(findConfig);
      }
    },
    [rpaConfig]
  );

  const handleChangeBreadcrumbs = useCallback((value: string) => {
    if (value === 'config') {
      setBreadcrumbs([]);
      setCardSelected({} as CardSelectedType);
      setSchedulerSelected({} as Scheduler);
    }
  }, []);

  return (
    <div className=" pb-6 pl-6 pr-6">
      {!showDetailsConfig && rpaConfig?.config?.scheduler != null && (
        <Stack spacing={'16px'} mb={'16px'}>
          <CardChooseConfig
            key={'SCHEDULER'}
            type={'SCHEDULER' as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
            onSelected={handleSelectCard}
          />
        </Stack>
      )}

      {!showDetailsConfig && (
        <Stack spacing={'16px'}>
          {rpaConfig?.config?.outbounds.map(config => (
            <CardChooseConfig
              key={config.id}
              type={config.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
              onSelected={handleSelectCard}
            />
          ))}
        </Stack>
      )}

      {showDetailsConfig && (
        <>
          <Breadcrumbs
            items={breadcrumbs}
            active={cardSelected?.name}
            onChange={handleChangeBreadcrumbs}
          />

          {JSON.stringify(cardSelected) !== '{}' && (
            <CardIntegration
              key={cardSelected.id}
              type={cardSelected.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES}
              data={cardSelected.credential}
              configId={cardSelected.id}
            />
          )}
          {JSON.stringify(schedulerSelected) !== '{}' && (
            <CardIntegration
              key={cardSelected.id}
              type={ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER}
              data={cardSelected.credential}
              scheduler={schedulerSelected}
              configId={cardSelected.id}
            />
          )}
        </>
      )}
    </div>
  );
};
