export const endpointsUploader = {
  getRpaActionEndpoint: 'rpa/actions',
  getRpaConfigByRpaId: 'rpa/scheduler/rpa-config/:rpaId',
  getRpaFlow: 'rpa/flows',
  getModelCSV: 'rpa/csv/model',
  postCSV: 'rpa/csv',
  postCleanAll: 'rpa/actions/clean-all',
  postCleanById: 'rpa/actions/clean',
  postClean: 'rpa/actions/clean',
  postDownloadActionAll: 'rpa/actions/download-all',
  postDownloadAction: 'rpa/actions/download',
  postDownloadFlowAll: 'rpa/flows/download-all',
  postDownloadFlow: 'rpa/flows/download',
} as const

export const endpointsPagarME = {
  getRecipients: '/v1/pagar-me/recipients',
  getPlans: '/v1/pagar-me/plans',
  postTestCredential: '/v1/pagar-me/credential',
} as const

export const endpointsFlow = {
  postInit: 'rpa/flow/init',
} as const
