/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from '@intraversa-lab/styleguide'
import { Box } from '@mui/material'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

type TOptions = {
  label: string
  value: string
}

interface ISelectRHF {
  options: TOptions[]
}

export function SelectRHF<FormType extends FieldValues>({
  placeholder,
  name,
  rules,
  control,
  options,
  ...rest
}: {
  placeholder?: string
  name: string
} & ISelectRHF &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Box>
          <Select
            {...rest}
            placeholder={placeholder}
            value={options.find((item) => String(item.value) === String(value))}
            options={options as any}
            onChange={(value: TOptions) => onChange(value.value)}
          />
        </Box>
      )}
    />
  )
}
