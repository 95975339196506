import { Routes as Router, Route, Navigate } from 'react-router-dom'

import { privateRoutes } from './privateRoutes'

export const Routes = (props) => {
  const isAuthenticated = true

  return (
    <Router>
      {privateRoutes.map((route, index) => {
        const hasSubRoute = !!route?.subRoutes?.length
        if (route.isPrivate && !isAuthenticated) {
          return (
            <Route
              key={index}
              path={route.path}
              element={<Navigate to="/" />}
            />
          )
        }
        if (hasSubRoute && route.template) {
          return (
            <Route key={index} path={route.path} element={<route.template />}>
              {route?.subRoutes?.map((subRoute, indexSubroute) => (
                <Route
                  key={indexSubroute}
                  path={subRoute.path}
                  element={<subRoute.component {...props} />}
                />
              ))}
            </Route>
          )
        }
        if (route.component) {
          return (
            <Route
              {...props}
              key={index}
              path={route.path}
              element={<route.component {...props} />}
            />
          )
        }
        return <Route key={index} path="*" element={<div>nao existe</div>} />
      })}
      <Route path="*" element={<div>nao existe</div>} />
    </Router>
  )
}
