/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'configs'
import { endpointsPagarME } from 'infra'

import { IResponsePlans, IResponseRecipients } from './pagarMeTypes'

async function getRecipients(): Promise<IResponseRecipients[]> {
  const response = await api.get(endpointsPagarME.getRecipients)
  return response.data
}

async function getPlans(): Promise<IResponsePlans[]> {
  const response = await api.get(endpointsPagarME.getPlans)
  return response.data
}

async function postTestConnection(): Promise<any> {
  const response = await api.post(endpointsPagarME.postTestCredential)
  return response.data
}

export const pagarMeApi = {
  getRecipients,
  getPlans,
  postTestConnection,
}
