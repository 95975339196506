export const IconEdit = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.13068 13.7501C0.868178 13.8126 0.640053 13.747 0.446303 13.5532C0.252553 13.3595 0.186928 13.1313 0.249428 12.8688L0.999428 9.2876L4.71193 13.0001L1.13068 13.7501ZM4.71193 13.0001L0.999428 9.2876L9.86818 0.418848C10.0807 0.206348 10.3432 0.100098 10.6557 0.100098C10.9682 0.100098 11.2307 0.206348 11.4432 0.418848L13.5807 2.55635C13.7932 2.76885 13.8994 3.03135 13.8994 3.34385C13.8994 3.65635 13.7932 3.91885 13.5807 4.13135L4.71193 13.0001ZM10.6557 1.2251L2.36818 9.5126L4.48693 11.6313L12.7744 3.34385L10.6557 1.2251Z"
        fill="#161F28"
      />
      <path
        d="M1.13068 13.7501C0.868178 13.8126 0.640053 13.747 0.446303 13.5532C0.252553 13.3595 0.186928 13.1313 0.249428 12.8688L0.999428 9.2876L4.71193 13.0001L1.13068 13.7501ZM4.71193 13.0001L0.999428 9.2876L9.86818 0.418848C10.0807 0.206348 10.3432 0.100098 10.6557 0.100098C10.9682 0.100098 11.2307 0.206348 11.4432 0.418848L13.5807 2.55635C13.7932 2.76885 13.8994 3.03135 13.8994 3.34385C13.8994 3.65635 13.7932 3.91885 13.5807 4.13135L4.71193 13.0001ZM10.6557 1.2251L2.36818 9.5126L4.48693 11.6313L12.7744 3.34385L10.6557 1.2251Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13068 13.7501C0.868178 13.8126 0.640053 13.747 0.446303 13.5532C0.252553 13.3595 0.186928 13.1313 0.249428 12.8688L0.999428 9.2876L4.71193 13.0001L1.13068 13.7501ZM4.71193 13.0001L0.999428 9.2876L9.86818 0.418848C10.0807 0.206348 10.3432 0.100098 10.6557 0.100098C10.9682 0.100098 11.2307 0.206348 11.4432 0.418848L13.5807 2.55635C13.7932 2.76885 13.8994 3.03135 13.8994 3.34385C13.8994 3.65635 13.7932 3.91885 13.5807 4.13135L4.71193 13.0001ZM10.6557 1.2251L2.36818 9.5126L4.48693 11.6313L12.7744 3.34385L10.6557 1.2251Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13068 13.7501C0.868178 13.8126 0.640053 13.747 0.446303 13.5532C0.252553 13.3595 0.186928 13.1313 0.249428 12.8688L0.999428 9.2876L4.71193 13.0001L1.13068 13.7501ZM4.71193 13.0001L0.999428 9.2876L9.86818 0.418848C10.0807 0.206348 10.3432 0.100098 10.6557 0.100098C10.9682 0.100098 11.2307 0.206348 11.4432 0.418848L13.5807 2.55635C13.7932 2.76885 13.8994 3.03135 13.8994 3.34385C13.8994 3.65635 13.7932 3.91885 13.5807 4.13135L4.71193 13.0001ZM10.6557 1.2251L2.36818 9.5126L4.48693 11.6313L12.7744 3.34385L10.6557 1.2251Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M1.13068 13.7501C0.868178 13.8126 0.640053 13.747 0.446303 13.5532C0.252553 13.3595 0.186928 13.1313 0.249428 12.8688L0.999428 9.2876L4.71193 13.0001L1.13068 13.7501ZM4.71193 13.0001L0.999428 9.2876L9.86818 0.418848C10.0807 0.206348 10.3432 0.100098 10.6557 0.100098C10.9682 0.100098 11.2307 0.206348 11.4432 0.418848L13.5807 2.55635C13.7932 2.76885 13.8994 3.03135 13.8994 3.34385C13.8994 3.65635 13.7932 3.91885 13.5807 4.13135L4.71193 13.0001ZM10.6557 1.2251L2.36818 9.5126L4.48693 11.6313L12.7744 3.34385L10.6557 1.2251Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}

export const IconReload = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9987 13.6668C5.15148 13.6668 3.57856 13.0175 2.27995 11.7189C0.981337 10.4203 0.332031 8.84738 0.332031 7.00016C0.332031 5.15294 0.981337 3.58002 2.27995 2.28141C3.57856 0.982802 5.15148 0.333496 6.9987 0.333496C8.17925 0.333496 9.21398 0.573079 10.1029 1.05225C10.9918 1.53141 11.7626 2.18766 12.4154 3.021V0.333496H13.6654V5.62516H8.3737V4.37516H11.8737C11.3459 3.54183 10.6723 2.86822 9.85286 2.35433C9.03342 1.84044 8.08203 1.5835 6.9987 1.5835C5.48481 1.5835 4.20356 2.1078 3.15495 3.15641C2.10634 4.20502 1.58203 5.48627 1.58203 7.00016C1.58203 8.51405 2.10634 9.7953 3.15495 10.8439C4.20356 11.8925 5.48481 12.4168 6.9987 12.4168C8.15148 12.4168 9.20703 12.087 10.1654 11.4272C11.1237 10.7675 11.7904 9.896 12.1654 8.81266H13.457C13.0543 10.271 12.2556 11.4446 11.0612 12.3335C9.86675 13.2224 8.51259 13.6668 6.9987 13.6668Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}
