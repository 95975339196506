import { QueryKeys } from 'infra'
import { useQuery } from 'react-query'

import { actionService } from '../pagarMeService'

export function useGetPlans() {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.GetPlans],
    queryFn: async () => {
      const response = await actionService.getPlans()
      return response
    },
  })

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
