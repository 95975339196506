import React, { useState } from 'react'

import { IconClose } from 'assets/IconClose'
import { IconFilter } from 'assets/IconFilter'
import { InputSearch } from 'components/InputSearch'

import * as S from './styles'

interface Filters {
  filterBy: string
  search: string
}
interface IFilterProps {
  options: { value: string; label: string }[]
  onChange: (filters: Filters) => void
}

export const FilterTo = ({ options, onChange }: IFilterProps) => {
  const [showDropFilter, setShowDropFilter] = useState(false)
  const [filters, setFilters] = useState({
    filterBy: '',
    search: '',
  })

  const handleOnChange = (name: keyof Filters, value: string) => {
    setFilters((old) => ({
      ...old,
      [name]: value,
    }))
    onChange({
      ...filters,
      [name]: value,
    })
  }

  function resetFilterTo() {
    const resetFields = {
      filterBy: '',
      search: '',
    }

    setFilters(resetFields)
    onChange(resetFields)
    setShowDropFilter(false)
  }

  return (
    <S.ContentButtonFilter>
      <S.ButtonFilter isFilter={showDropFilter}>
        <div onClick={() => setShowDropFilter(!showDropFilter)}>
          <IconFilter />
          Filtrar por
        </div>

        {!!filters.filterBy && (
          <div className="icon-close-filter" onClick={resetFilterTo}>
            <IconClose />
          </div>
        )}
      </S.ButtonFilter>

      {showDropFilter && (
        <>
          <div className="drop-filter">
            <InputSearch
              placeholder="Pesquisa"
              value={filters.search}
              onChange={(e) => handleOnChange('search', e.target.value)}
            />

            <div className="flex justify-between w-full mt-6 mb-3">
              <p
                className="font-medium"
                style={{ fontFamily: 'Roboto', color: '#75808A' }}
              >
                Status
              </p>
              <p
                className="text-xs font-medium"
                style={{ color: '#75808A', fontFamily: 'Roboto' }}
              >
                Limpar
              </p>
            </div>

            <S.ContentFilteButton>
              {options.map((op, index) => (
                <label key={index}>
                  {op.label}
                  <input
                    type="radio"
                    id={op.value}
                    name="filtro"
                    value={op.value}
                    checked={filters.filterBy === op.value}
                    onClick={() => handleOnChange('filterBy', op.value)}
                  />
                </label>
              ))}
            </S.ContentFilteButton>
          </div>

          <div
            onClick={() => setShowDropFilter(!showDropFilter)}
            className="overlay-drop-filter"
          ></div>
        </>
      )}
    </S.ContentButtonFilter>
  )
}
