import { z } from 'zod';

export const unitarySchema = z.object({
  name: z.string().min(1, 'Campo Obrigatório'),
  playlistId: z.string().min(1, 'Campo Obrigatório'),
  isPlaylist: z.boolean(),
  sheetBase: z.string({
    required_error: 'Campo Obrigatório'
  }),
  initCellNumber: z.number(),
  initHeaderCellNumber: z.number(),
  minutesInterval: z.number(),
  liveHourTime: z.number(),
  initCellLetter: z.string({ required_error: 'Campo Obrigatório' }),
  initHeaderCellLetter: z.string({ required_error: 'Campo Obrigatório' })
});

export type unitarySchema = z.infer<typeof unitarySchema>;

// cancelation
export const unitaryCancellationSchema = z.object({
  assinatura: z.number().min(1, 'Campo Obrigatório'),
  nome: z.string(),
  email: z.string()
});

export type unitaryCancellationSchema = z.infer<typeof unitarySchema>;
