import { z } from 'zod'

export const unitarySchema = z.object({
  assinatura: z.number().min(1, 'Campo Obrigatório'),
  novaAssinatura: z.boolean(),
  novoPlano: z.string({
    required_error: 'Campo Obrigatório',
  }),
  nome: z.string(),
  email: z.string(),
  tipoConta: z.string({
    required_error: 'Campo Obrigatório',
  }),
})

export type unitarySchema = z.infer<typeof unitarySchema>

// cancelation
export const unitaryCancellationSchema = z.object({
  assinatura: z.number().min(1, 'Campo Obrigatório'),
  nome: z.string(),
  email: z.string(),
})

export type unitaryCancellationSchema = z.infer<typeof unitarySchema>
