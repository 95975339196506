/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationOptions } from 'infra'
import { useMutation } from 'react-query'

import { actionService } from '../actionService'

export function useUploadCSV(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, any>({
    mutationFn: (file: File) => actionService.postUploadCSV(file),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    onUploadCSV: (file: File) => mutation.mutate(file),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
