import React from 'react'

export const IconStatus = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.025 14.55L16.1 7.475L14.95 6.35L9.025 12.275L6.025 9.275L4.9 10.4L9.025 14.55ZM10.5 20C9.13333 20 7.84167 19.7375 6.625 19.2125C5.40833 18.6875 4.34583 17.9708 3.4375 17.0625C2.52917 16.1542 1.8125 15.0917 1.2875 13.875C0.7625 12.6583 0.5 11.3667 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.52917 3.825 3.4375 2.925C4.34583 2.025 5.40833 1.3125 6.625 0.7875C7.84167 0.2625 9.13333 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3667 20.2375 12.6583 19.7125 13.875C19.1875 15.0917 18.475 16.1542 17.575 17.0625C16.675 17.9708 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20Z"
        fill="#6D42E1"
      />
    </svg>
  )
}
