/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@intraversa-lab/styleguide'
import { MutationOptions } from 'infra'
import { useMutation } from 'react-query'

import { actionService } from '../actionService'

export function usePostCleanAll(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, any>({
    mutationFn: () => actionService.postCleanAll(),
    retry: false,
    onError: (error) => {
      toast({
        text: 'Erro',
        description: 'Erro ao excluir dados da tabela',
        type: 'error',
      })
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    onCleanAll: () => mutation.mutate({}),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
