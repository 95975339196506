import React from 'react'

interface IIconProps {
  color?: string
}

export const IconSave = ({ color }: IIconProps) => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6875 14.675L13.425 11.9375L12.6375 11.15L11.25 12.5375V9.14375H10.125V12.5375L8.7375 11.15L7.95 11.9375L10.6875 14.675ZM7.875 17V15.875H13.5V17H7.875ZM1.125 14C0.825 14 0.5625 13.8875 0.3375 13.6625C0.1125 13.4375 0 13.175 0 12.875V1.625C0 1.325 0.1125 1.0625 0.3375 0.8375C0.5625 0.6125 0.825 0.5 1.125 0.5H6.8625L11.25 4.8875V8.01875H10.125V5.375H6.375V1.625H1.125V12.875H6.75V14H1.125Z"
        fill={color ?? '#161F28'}
      />
      <path
        d="M10.6875 14.675L13.425 11.9375L12.6375 11.15L11.25 12.5375V9.14375H10.125V12.5375L8.7375 11.15L7.95 11.9375L10.6875 14.675ZM7.875 17V15.875H13.5V17H7.875ZM1.125 14C0.825 14 0.5625 13.8875 0.3375 13.6625C0.1125 13.4375 0 13.175 0 12.875V1.625C0 1.325 0.1125 1.0625 0.3375 0.8375C0.5625 0.6125 0.825 0.5 1.125 0.5H6.8625L11.25 4.8875V8.01875H10.125V5.375H6.375V1.625H1.125V12.875H6.75V14H1.125Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M10.6875 14.675L13.425 11.9375L12.6375 11.15L11.25 12.5375V9.14375H10.125V12.5375L8.7375 11.15L7.95 11.9375L10.6875 14.675ZM7.875 17V15.875H13.5V17H7.875ZM1.125 14C0.825 14 0.5625 13.8875 0.3375 13.6625C0.1125 13.4375 0 13.175 0 12.875V1.625C0 1.325 0.1125 1.0625 0.3375 0.8375C0.5625 0.6125 0.825 0.5 1.125 0.5H6.8625L11.25 4.8875V8.01875H10.125V5.375H6.375V1.625H1.125V12.875H6.75V14H1.125Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M10.6875 14.675L13.425 11.9375L12.6375 11.15L11.25 12.5375V9.14375H10.125V12.5375L8.7375 11.15L7.95 11.9375L10.6875 14.675ZM7.875 17V15.875H13.5V17H7.875ZM1.125 14C0.825 14 0.5625 13.8875 0.3375 13.6625C0.1125 13.4375 0 13.175 0 12.875V1.625C0 1.325 0.1125 1.0625 0.3375 0.8375C0.5625 0.6125 0.825 0.5 1.125 0.5H6.8625L11.25 4.8875V8.01875H10.125V5.375H6.375V1.625H1.125V12.875H6.75V14H1.125Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M10.6875 14.675L13.425 11.9375L12.6375 11.15L11.25 12.5375V9.14375H10.125V12.5375L8.7375 11.15L7.95 11.9375L10.6875 14.675ZM7.875 17V15.875H13.5V17H7.875ZM1.125 14C0.825 14 0.5625 13.8875 0.3375 13.6625C0.1125 13.4375 0 13.175 0 12.875V1.625C0 1.325 0.1125 1.0625 0.3375 0.8375C0.5625 0.6125 0.825 0.5 1.125 0.5H6.8625L11.25 4.8875V8.01875H10.125V5.375H6.375V1.625H1.125V12.875H6.75V14H1.125Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
