import { Box } from '@mui/material'
import { styled } from 'styled-components'

export const Wrapper = styled(Box)`
  display: flex;
  gap: 16px;

  @media (max-width: 750px) {
    width: 100%;
    button {
      border: 1px solid #bec9d2;
      flex: 1;
    }
  }

  @media (max-width: 650px) {
    gap: 0;
  }
`

export const ContentButtonFilter = styled.div`
  position: relative;

  max-width: 400px;

  .drop-filter {
    position: absolute;
    z-index: 1000;
    right: 0px;
    top: 55px;
    padding: 16px 16px 24px 16px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 320px;
    cursor: default;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */

    span {
      color: #75808a;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .overlay-drop-filter {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.16); */
    z-index: 1;
  }
`

type TFilter = {
  isFilter: boolean
}

export const ContentFilteButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;

  label {
    padding: 8px 16px;
    border-radius: 40px;
    color: #3c454d;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    border: 1px solid #dfe4e9;

    input[type='radio'] {
      display: none;
    }

    &:has(> input:checked) {
      color: #6d42e1;
      border: 1px solid #6d42e1;
    }

    &:hover {
      background-color: #eff2f4;

      label {
        color: #3c454d;
      }
    }
  }
`

export const ButtonFilter = styled.button<TFilter>`
  padding: 12px 16px;
  height: 44px;
  background-color: transparent;
  border: ${(props) =>
    props.isFilter ? '1px solid #6D42E1' : '1px solid #bec9d2'};
  display: flex;
  align-items: center;
  gap: 8px;
  /* z-index: 1; */

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${(props) => (props.isFilter ? '#6D42E1' : '#161f28')};
  cursor: pointer;
  position: relative;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg,
  img {
    filter: ${(props) =>
      props.isFilter
        ? 'brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(3555%) hue-rotate(246deg) brightness(90%) contrast(96%)'
        : ''};
  }

  /* &:active {
    background-color: #dfe4e9 !important;
  } */

  /* &:focus {
    background-color: #dfe4e9 !important;
  } */

  .icon-close-filter {
    margin-left: 0px;
    padding-left: 0px;

    svg,
    img {
      filter: ${(props) =>
        props.isFilter
          ? 'brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(3555%) hue-rotate(246deg) brightness(90%) contrast(96%)'
          : ''};
    }
  }

  &:hover {
    background-color: #eff2f4;
  }
`

export const dropFilter = styled.div``
