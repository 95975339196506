import { pagarMeApi } from './pagarMeApi'
import { IResponsePlans, IResponseRecipients } from './pagarMeTypes'

async function getRecipients(): Promise<IResponseRecipients[]> {
  try {
    const response = await pagarMeApi.getRecipients()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

async function getPlans(): Promise<IResponsePlans[]> {
  try {
    const response = await pagarMeApi.getPlans()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

async function postTestConnection(): Promise<IResponsePlans[]> {
  try {
    const response = await pagarMeApi.postTestConnection()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

export const actionService = {
  getRecipients,
  getPlans,
  postTestConnection,
}
