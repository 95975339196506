import React, { useEffect, useMemo, useState } from 'react';

import { Button, toast, useConfirmModal } from '@intraversa-lab/styleguide';
// import { IconClose } from 'assets/IconClose'
import { IconDownload } from 'assets/IconDownload';
import { IconPlus } from 'assets/IconPlus';
import { IconStatus } from 'assets/IconStatus';
import { IconTrash } from 'assets/IconTrash';
import { IconUpdate } from 'assets/IconUpdate';
import { IconUpload } from 'assets/IconUpload';
import { ButtonInfo } from 'components/ButtonInfo/ButtonInfo';
import { FilterTo } from 'components/FilterTo/FilterTo';
import { ModalUnitaryRenawel } from 'components/ModalUnitaryRenawel';
import { ModalNewLive } from 'components/ModalNewLive/index';
import { ModalUpload } from 'components/ModalUpload/ModalUpload';
import { Table } from 'components/Table';
import { queryClient } from 'configs/queryClient';
import { IdataAction } from 'domains/ActionConfig/actionTypes';
import { useGetAllRpaAction } from 'domains/ActionConfig/useCases/useGetAllAction';
import { useGetDownloadAction } from 'domains/ActionConfig/useCases/useGetDownloadAction';
import { usePostCleanAll } from 'domains/ActionConfig/useCases/usePostCleanAll';
import { usePostCleanAssinaturaById } from 'domains/ActionConfig/useCases/usePostCleanAssinaturaById';
import { usePostInit } from 'domains/Flow/useCase/usePostinit';
import { QueryKeys } from 'infra';
import moment from 'moment';
import { UploaderTab } from 'types/UploaderConfig';
import { downloadDocumentNavigator } from 'utils/downlaodCSV';
import { formatColumns } from 'utils/formatColumn';
import { renderCellColumn } from 'utils/renderCellColumn';

export const Assinaturas = ({
  type,
  title,
  tabData
}: {
  type: string;
  title: string;
  tabData: UploaderTab;
}) => {
  const [filterBy, setFilterBy] = useState<string | null>('');
  const [tableSelected, setTableSelected] = useState<string[]>([]);
  const [modalRenovacao, setModalRenovacao] = useState(false);
  const [modalNewLive, setModalNewLive] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [paginationProps, setPaginationProps] = useState({
    offset: 10,
    page: 0,
    total: 1
  });

  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal();

  const { onCleanAll } = usePostCleanAll({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      toast({
        text: 'Sucesso',
        description: 'Os dados da tabela foram deletado com sucesso',
        type: 'success'
      });
    }
  });

  const { onCleanById } = usePostCleanAssinaturaById({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      setTableSelected([]);
      toast({
        text: 'Sucesso',
        description: 'Os dados da tabela foram deletado com sucesso',
        type: 'success'
      });
    }
  });

  const { actions, refetch, isLoading } = useGetAllRpaAction({
    page: paginationProps.page,
    offset: paginationProps.offset,
    sort: 'created_at',
    status: filterBy
  });

  const { onDownload, isLoading: isLoadingDownload } = useGetDownloadAction({
    onSuccess: data => {
      downloadDocumentNavigator(data.base64, 'assinaturas');
    }
  });
  const { onInit, isLoading: isLoadingInit } = usePostInit({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
    }
  });

  const handleOnRefetch = () => {
    queryClient.removeQueries(QueryKeys.GetFindAllActionRpa);
    refetch();
  };

  const handleDownload = () => {
    onDownload(tableSelected.filter(item => item !== 'all'));
  };

  const columns = useMemo(() => {
    return tabData.columns?.map(item => ({
      ...item,
      ...(!!item?.format && { format: formatColumns[item.format] }),
      ...(!!item?.renderCell && {
        renderCell: renderCellColumn[item.renderCell]
      })
    }));
  }, [tabData.columns]);

  function parseStatus(status: number): string {
    switch (status) {
      case 0:
        return 'Pendente';
      case 200:
        return 'Sucesso';
      default:
        return 'Erro';
    }
  }

  function parseStatusColor(status: number): string {
    switch (status) {
      case 0:
        return 'text-xs font-medium bg-gray-200 text-gray-700 rounded-md pt-[2px] pb-[2px]';
      case 200:
        return 'text-xs font-medium bg-green-100 text-green-900 rounded-md pt-[2px] pb-[2px]';
      default:
        return 'text-xs font-medium bg-red-100 text-red-600 rounded-md pt-[2px] pb-[2px]';
    }
  }

  useEffect(() => {
    if (actions) {
      setPaginationProps(old => ({ ...old, total: actions.total }));
    }
  }, [actions]);

  console.log(tabData);

  return (
    <div
      className="pt-6 pb-6 pl-6 pr-6 ml-6 mr-6 rounded bg-white"
      style={{ border: '1px solid #DFE4E9' }}
    >
      <div>
        <p className="font-medium">{tabData.title}</p>
        <span className="font-normal text-sm text-gray-700">
          {tabData.description}
        </span>

        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center gap-3 ">
            {tabData.actions.uploader.active && (
              <Button
                variant="outlined"
                className="flex gap-2"
                onClick={() => setModalUpload(!modalUpload)}
                style={{
                  textTransform: 'none',
                  border: '1px solid #6D42E1',
                  color: '#6D42E1',
                  fontSize: '16px',
                  padding: '12px 24px 12px 19px',
                  height: '44px'
                }}
              >
                <IconUpload color="#6D42E1" />
                {tabData.actions?.uploader?.label}
              </Button>
            )}

            {tabData.actions.unitaryAction.active && (
              <Button
                variant="outlined"
                onClick={() => setModalRenovacao(!modalRenovacao)}
                className="flex gap-2"
                style={{
                  textTransform: 'none',
                  border: '1px solid #6D42E1',
                  color: '#6D42E1',
                  fontSize: '16px',
                  padding: '12px 24px 12px 19px',
                  height: '44px'
                }}
              >
                <IconPlus color="#6D42E1" />
                {tabData.actions.unitaryAction.label}
              </Button>
            )}

            {tabData.actions.addLive?.active && (
              <Button
                variant="outlined"
                onClick={() => setModalNewLive(!modalNewLive)}
                className="flex gap-2"
                style={{
                  textTransform: 'none',
                  border: '1px solid #6D42E1',
                  color: '#6D42E1',
                  fontSize: '16px',
                  padding: '12px 24px 12px 19px',
                  height: '44px'
                }}
              >
                <IconPlus color="#6D42E1" />
                {tabData.actions.addLive?.label}
              </Button>
            )}

            {tabData.actions.run.active && (
              <Button
                variant="contained"
                style={{
                  textTransform: 'none',
                  fontSize: '16px',
                  padding: '12px 24px ',
                  height: '44px'
                }}
                disabled={isLoadingInit}
                onClick={() =>
                  updateModalStateAndOpenModal({
                    title: `Executar ${title}`,
                    description: `Ao confirmar essa ação você irá executar o fluxo de integração. Deseja continuar?`,
                    onConfirm: () => onInit(),
                    maxWidth: 441,
                    type: 'light'
                  })
                }
              >
                {tabData.actions.run.label}
              </Button>
            )}
          </div>

          <div className="flex gap-3 items-center">
            {tabData.downloadOption && (
              <button
                className="relative h-11 w-11 px-3 py-3 border border-solid border-gray-300 disabled:opacity-50"
                onClick={handleDownload}
                disabled={isLoadingDownload}
              >
                <IconDownload />
                {!!tableSelected.filter(item => item !== 'all').length && (
                  <div className="bg-[#6D42E1] absolute bottom-[30px] right-[-6px] w-[20px] h-[20px] rounded-full flex items-center justify-center">
                    <span className="text-sm text-white">
                      {tableSelected.filter(item => item !== 'all').length}
                    </span>
                  </div>
                )}
              </button>
            )}

            <button
              className="h-11 w-11 px-3 py-3 border border-solid border-gray-300 disabled:opacity-50"
              onClick={handleOnRefetch}
              disabled={isLoading}
            >
              <IconUpdate />
            </button>

            <FilterTo
              onChange={({ filterBy }) => setFilterBy(filterBy)}
              options={[
                { label: 'Pendente', value: 'PENDING' },
                { label: 'Sucesso', value: 'SUCCESS' },
                { label: 'Erro', value: 'ERROR' }
              ]}
            />

            {tabData.cleanOption && (
              <button
                className="h-11 flex gap-1 items-center px-4 py-3 border border-solid border-gray-300 relative"
                onClick={() =>
                  updateModalStateAndOpenModal({
                    title: 'Limpar dados da tabela',
                    description: tableSelected.length
                      ? 'Ao confirmar essa ação você irá os dados selecionados da tabela. Deseja continuar?'
                      : 'Ao confirmar essa ação você irá limpar todos os dados da tabela. Deseja continuar?',
                    onConfirm: () =>
                      !tableSelected.length
                        ? onCleanAll()
                        : onCleanById(
                            tableSelected.filter(item => item !== 'all')
                          ),
                    maxWidth: 441,
                    type: 'light'
                  })
                }
              >
                <IconTrash />
                <span className="font-medium">Limpar</span>

                {!!tableSelected.filter(item => item !== 'all').length && (
                  <div className="bg-[#6D42E1] absolute bottom-[30px] right-[-6px] w-[20px] h-[20px] rounded-full flex items-center justify-center">
                    <span className="text-sm text-white">
                      {tableSelected.filter(item => item !== 'all').length}
                    </span>
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      <Table
        columns={columns as any}
        paginable
        isLoading={isLoading}
        tableSelected
        typeSelected="checkbox"
        paginationProps={paginationProps}
        onChange={value => setTableSelected(value as string[])}
        value={tableSelected}
        handleChangePage={page => setPaginationProps(old => ({ ...old, page }))}
        handleChangeRowsPerPage={offset =>
          setPaginationProps(old => ({ ...old, offset }))
        }
        data={
          actions
            ? actions.data.map((a: IdataAction) => ({
                ...a,
                nome: a.clientName,
                email: a.clientEmail,
                assinatura: a.subscription,
                novo_plano: a.newPlanId,
                status: (
                  <p className={parseStatusColor(a.statusCodeApi)}>
                    {parseStatus(a.statusCodeApi)}
                  </p>
                ),
                // resposta_pagar: a.responseApi,
                resposta_pagar: a.responseApi,
                conta: a.recipientId,
                nova_assinatura: (
                  <p className="flex justify-center ">
                    {a.newSubscription ? <IconStatus /> : null}
                  </p>
                ),
                id: a.id
              }))
            : []
        }
      />

      {modalRenovacao && (
        <ModalUnitaryRenawel
          isOpen={modalRenovacao}
          onClose={() => setModalRenovacao(!modalRenovacao)}
          type={type}
          title={title}
        />
      )}

      {modalNewLive && (
        <ModalNewLive
          isOpen={modalNewLive}
          onClose={() => setModalNewLive(!modalNewLive)}
          type={type}
          title={title}
        />
      )}

      {modalUpload && (
        <ModalUpload
          isOpen={modalUpload}
          onClose={() => setModalUpload(!modalUpload)}
          onSuccess={() => refetch()}
        />
      )}

      {renderModal()}
    </div>
  );
};
