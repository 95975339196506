import React from 'react'

export const IconFilter = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33333 10V8.75H9.66667V10H6.33333ZM3 5.625V4.375H13V5.625H3ZM0.5 1.25V0H15.5V1.25H0.5Z"
        fill="#161F28"
      />
      <path
        d="M6.33333 10V8.75H9.66667V10H6.33333ZM3 5.625V4.375H13V5.625H3ZM0.5 1.25V0H15.5V1.25H0.5Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M6.33333 10V8.75H9.66667V10H6.33333ZM3 5.625V4.375H13V5.625H3ZM0.5 1.25V0H15.5V1.25H0.5Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M6.33333 10V8.75H9.66667V10H6.33333ZM3 5.625V4.375H13V5.625H3ZM0.5 1.25V0H15.5V1.25H0.5Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M6.33333 10V8.75H9.66667V10H6.33333ZM3 5.625V4.375H13V5.625H3ZM0.5 1.25V0H15.5V1.25H0.5Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
