import { useEffect, useState } from 'react'

import { Button } from '@intraversa-lab/styleguide'
import { Progress as ProgressPrimitive } from 'components/Primitive/Progress'

export const IconProgress = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6 33.9H17.6V23.85L21.7 27.95L23.8 25.85L16 18.25L8.3 25.95L10.4 28.05L14.6 23.85V33.9ZM3 40C2.2 40 1.5 39.7 0.9 39.1C0.3 38.5 0 37.8 0 37V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H21.05L32 10.95V37C32 37.8 31.7 38.5 31.1 39.1C30.5 39.7 29.8 40 29 40H3ZM19.55 12.3V3H3V37H29V12.3H19.55Z"
        fill="#75808A"
      />
    </svg>
  )
}

export const IconCancel = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2248 13.8258L0.174805 12.7758L5.94981 7.00078L0.174805 1.22578L1.2248 0.175781L6.99981 5.95078L12.7748 0.175781L13.8248 1.22578L8.04981 7.00078L13.8248 12.7758L12.7748 13.8258L6.99981 8.05078L1.2248 13.8258Z"
        fill="#59636B"
      />
    </svg>
  )
}

interface IProgressProps {
  onRemove: () => void
  fileName: string
  progress?: number
}
export const Progress = ({
  onRemove,
  fileName,
  progress = 0,
}: IProgressProps) => {
  return (
    <div className="min-h-[180px] mt-6 mb-8 flex items-center">
      <div className="border border-[#BEC9D2] pt-4 pb w-full flex items-center justify-between py-5 px-[28px] rounded-[4px] box-border h-[80px]">
        <IconProgress />
        <div className="flex-1 ml-[24px] mr-8">
          <div className="flex items-center justify-between">
            <p className="text-base text-[#59636B]">{fileName}</p>
            <p className="text-base text-[#59636B]">{progress}%</p>
          </div>

          <ProgressPrimitive value={progress} className="mt-2" />
        </div>

        <button
          type="button"
          className="bg-transparent border-none"
          onClick={onRemove}
        >
          <IconCancel />
        </button>
      </div>
    </div>
  )
}
