export const IconInfo = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00035 10.334C7.15611 10.334 7.28676 10.2813 7.39232 10.176C7.49787 10.0706 7.55065 9.94003 7.55065 9.78428C7.55065 9.62853 7.49797 9.49787 7.39262 9.39232C7.28725 9.28676 7.1567 9.23398 7.00095 9.23398C6.8452 9.23398 6.71454 9.28666 6.60898 9.39202C6.50343 9.49739 6.45065 9.62794 6.45065 9.78368C6.45065 9.93944 6.50333 10.0701 6.60868 10.1757C6.71405 10.2812 6.84461 10.334 7.00035 10.334ZM6.55065 7.78398H7.55065V3.56732H6.55065V7.78398ZM7.00508 13.6673C6.08582 13.6673 5.22193 13.4923 4.41342 13.1423C3.60491 12.7923 2.89787 12.3145 2.29232 11.709C1.68676 11.1034 1.20898 10.396 0.858984 9.58663C0.508984 8.7773 0.333984 7.91253 0.333984 6.99232C0.333984 6.07211 0.508984 5.20733 0.858984 4.398C1.20898 3.58866 1.68676 2.88398 2.29232 2.28398C2.89787 1.68398 3.60532 1.20898 4.41467 0.858984C5.224 0.508984 6.08877 0.333984 7.00898 0.333984C7.9292 0.333984 8.79397 0.508984 9.6033 0.858984C10.4126 1.20898 11.1173 1.68398 11.7173 2.28398C12.3173 2.88398 12.7923 3.58954 13.1423 4.40065C13.4923 5.21176 13.6673 6.07695 13.6673 6.99622C13.6673 7.91548 13.4923 8.77937 13.1423 9.58788C12.7923 10.3964 12.3173 11.1024 11.7173 11.7059C11.1173 12.3094 10.4118 12.7872 9.60065 13.1393C8.78954 13.4913 7.92435 13.6673 7.00508 13.6673ZM7.00898 12.6673C8.58121 12.6673 9.91732 12.1145 11.0173 11.009C12.1173 9.90343 12.6673 8.56454 12.6673 6.99232C12.6673 5.4201 12.1184 4.08398 11.0205 2.98398C9.92253 1.88398 8.5826 1.33398 7.00065 1.33398C5.43398 1.33398 4.09787 1.88294 2.99232 2.98085C1.88676 4.07877 1.33398 5.41871 1.33398 7.00065C1.33398 8.56732 1.88676 9.90343 2.99232 11.009C4.09787 12.1145 5.43676 12.6673 7.00898 12.6673Z"
        fill="#161F28"
      />
      <path
        d="M7.00035 10.334C7.15611 10.334 7.28676 10.2813 7.39232 10.176C7.49787 10.0706 7.55065 9.94003 7.55065 9.78428C7.55065 9.62853 7.49797 9.49787 7.39262 9.39232C7.28725 9.28676 7.1567 9.23398 7.00095 9.23398C6.8452 9.23398 6.71454 9.28666 6.60898 9.39202C6.50343 9.49739 6.45065 9.62794 6.45065 9.78368C6.45065 9.93944 6.50333 10.0701 6.60868 10.1757C6.71405 10.2812 6.84461 10.334 7.00035 10.334ZM6.55065 7.78398H7.55065V3.56732H6.55065V7.78398ZM7.00508 13.6673C6.08582 13.6673 5.22193 13.4923 4.41342 13.1423C3.60491 12.7923 2.89787 12.3145 2.29232 11.709C1.68676 11.1034 1.20898 10.396 0.858984 9.58663C0.508984 8.7773 0.333984 7.91253 0.333984 6.99232C0.333984 6.07211 0.508984 5.20733 0.858984 4.398C1.20898 3.58866 1.68676 2.88398 2.29232 2.28398C2.89787 1.68398 3.60532 1.20898 4.41467 0.858984C5.224 0.508984 6.08877 0.333984 7.00898 0.333984C7.9292 0.333984 8.79397 0.508984 9.6033 0.858984C10.4126 1.20898 11.1173 1.68398 11.7173 2.28398C12.3173 2.88398 12.7923 3.58954 13.1423 4.40065C13.4923 5.21176 13.6673 6.07695 13.6673 6.99622C13.6673 7.91548 13.4923 8.77937 13.1423 9.58788C12.7923 10.3964 12.3173 11.1024 11.7173 11.7059C11.1173 12.3094 10.4118 12.7872 9.60065 13.1393C8.78954 13.4913 7.92435 13.6673 7.00508 13.6673ZM7.00898 12.6673C8.58121 12.6673 9.91732 12.1145 11.0173 11.009C12.1173 9.90343 12.6673 8.56454 12.6673 6.99232C12.6673 5.4201 12.1184 4.08398 11.0205 2.98398C9.92253 1.88398 8.5826 1.33398 7.00065 1.33398C5.43398 1.33398 4.09787 1.88294 2.99232 2.98085C1.88676 4.07877 1.33398 5.41871 1.33398 7.00065C1.33398 8.56732 1.88676 9.90343 2.99232 11.009C4.09787 12.1145 5.43676 12.6673 7.00898 12.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00035 10.334C7.15611 10.334 7.28676 10.2813 7.39232 10.176C7.49787 10.0706 7.55065 9.94003 7.55065 9.78428C7.55065 9.62853 7.49797 9.49787 7.39262 9.39232C7.28725 9.28676 7.1567 9.23398 7.00095 9.23398C6.8452 9.23398 6.71454 9.28666 6.60898 9.39202C6.50343 9.49739 6.45065 9.62794 6.45065 9.78368C6.45065 9.93944 6.50333 10.0701 6.60868 10.1757C6.71405 10.2812 6.84461 10.334 7.00035 10.334ZM6.55065 7.78398H7.55065V3.56732H6.55065V7.78398ZM7.00508 13.6673C6.08582 13.6673 5.22193 13.4923 4.41342 13.1423C3.60491 12.7923 2.89787 12.3145 2.29232 11.709C1.68676 11.1034 1.20898 10.396 0.858984 9.58663C0.508984 8.7773 0.333984 7.91253 0.333984 6.99232C0.333984 6.07211 0.508984 5.20733 0.858984 4.398C1.20898 3.58866 1.68676 2.88398 2.29232 2.28398C2.89787 1.68398 3.60532 1.20898 4.41467 0.858984C5.224 0.508984 6.08877 0.333984 7.00898 0.333984C7.9292 0.333984 8.79397 0.508984 9.6033 0.858984C10.4126 1.20898 11.1173 1.68398 11.7173 2.28398C12.3173 2.88398 12.7923 3.58954 13.1423 4.40065C13.4923 5.21176 13.6673 6.07695 13.6673 6.99622C13.6673 7.91548 13.4923 8.77937 13.1423 9.58788C12.7923 10.3964 12.3173 11.1024 11.7173 11.7059C11.1173 12.3094 10.4118 12.7872 9.60065 13.1393C8.78954 13.4913 7.92435 13.6673 7.00508 13.6673ZM7.00898 12.6673C8.58121 12.6673 9.91732 12.1145 11.0173 11.009C12.1173 9.90343 12.6673 8.56454 12.6673 6.99232C12.6673 5.4201 12.1184 4.08398 11.0205 2.98398C9.92253 1.88398 8.5826 1.33398 7.00065 1.33398C5.43398 1.33398 4.09787 1.88294 2.99232 2.98085C1.88676 4.07877 1.33398 5.41871 1.33398 7.00065C1.33398 8.56732 1.88676 9.90343 2.99232 11.009C4.09787 12.1145 5.43676 12.6673 7.00898 12.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00035 10.334C7.15611 10.334 7.28676 10.2813 7.39232 10.176C7.49787 10.0706 7.55065 9.94003 7.55065 9.78428C7.55065 9.62853 7.49797 9.49787 7.39262 9.39232C7.28725 9.28676 7.1567 9.23398 7.00095 9.23398C6.8452 9.23398 6.71454 9.28666 6.60898 9.39202C6.50343 9.49739 6.45065 9.62794 6.45065 9.78368C6.45065 9.93944 6.50333 10.0701 6.60868 10.1757C6.71405 10.2812 6.84461 10.334 7.00035 10.334ZM6.55065 7.78398H7.55065V3.56732H6.55065V7.78398ZM7.00508 13.6673C6.08582 13.6673 5.22193 13.4923 4.41342 13.1423C3.60491 12.7923 2.89787 12.3145 2.29232 11.709C1.68676 11.1034 1.20898 10.396 0.858984 9.58663C0.508984 8.7773 0.333984 7.91253 0.333984 6.99232C0.333984 6.07211 0.508984 5.20733 0.858984 4.398C1.20898 3.58866 1.68676 2.88398 2.29232 2.28398C2.89787 1.68398 3.60532 1.20898 4.41467 0.858984C5.224 0.508984 6.08877 0.333984 7.00898 0.333984C7.9292 0.333984 8.79397 0.508984 9.6033 0.858984C10.4126 1.20898 11.1173 1.68398 11.7173 2.28398C12.3173 2.88398 12.7923 3.58954 13.1423 4.40065C13.4923 5.21176 13.6673 6.07695 13.6673 6.99622C13.6673 7.91548 13.4923 8.77937 13.1423 9.58788C12.7923 10.3964 12.3173 11.1024 11.7173 11.7059C11.1173 12.3094 10.4118 12.7872 9.60065 13.1393C8.78954 13.4913 7.92435 13.6673 7.00508 13.6673ZM7.00898 12.6673C8.58121 12.6673 9.91732 12.1145 11.0173 11.009C12.1173 9.90343 12.6673 8.56454 12.6673 6.99232C12.6673 5.4201 12.1184 4.08398 11.0205 2.98398C9.92253 1.88398 8.5826 1.33398 7.00065 1.33398C5.43398 1.33398 4.09787 1.88294 2.99232 2.98085C1.88676 4.07877 1.33398 5.41871 1.33398 7.00065C1.33398 8.56732 1.88676 9.90343 2.99232 11.009C4.09787 12.1145 5.43676 12.6673 7.00898 12.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00035 10.334C7.15611 10.334 7.28676 10.2813 7.39232 10.176C7.49787 10.0706 7.55065 9.94003 7.55065 9.78428C7.55065 9.62853 7.49797 9.49787 7.39262 9.39232C7.28725 9.28676 7.1567 9.23398 7.00095 9.23398C6.8452 9.23398 6.71454 9.28666 6.60898 9.39202C6.50343 9.49739 6.45065 9.62794 6.45065 9.78368C6.45065 9.93944 6.50333 10.0701 6.60868 10.1757C6.71405 10.2812 6.84461 10.334 7.00035 10.334ZM6.55065 7.78398H7.55065V3.56732H6.55065V7.78398ZM7.00508 13.6673C6.08582 13.6673 5.22193 13.4923 4.41342 13.1423C3.60491 12.7923 2.89787 12.3145 2.29232 11.709C1.68676 11.1034 1.20898 10.396 0.858984 9.58663C0.508984 8.7773 0.333984 7.91253 0.333984 6.99232C0.333984 6.07211 0.508984 5.20733 0.858984 4.398C1.20898 3.58866 1.68676 2.88398 2.29232 2.28398C2.89787 1.68398 3.60532 1.20898 4.41467 0.858984C5.224 0.508984 6.08877 0.333984 7.00898 0.333984C7.9292 0.333984 8.79397 0.508984 9.6033 0.858984C10.4126 1.20898 11.1173 1.68398 11.7173 2.28398C12.3173 2.88398 12.7923 3.58954 13.1423 4.40065C13.4923 5.21176 13.6673 6.07695 13.6673 6.99622C13.6673 7.91548 13.4923 8.77937 13.1423 9.58788C12.7923 10.3964 12.3173 11.1024 11.7173 11.7059C11.1173 12.3094 10.4118 12.7872 9.60065 13.1393C8.78954 13.4913 7.92435 13.6673 7.00508 13.6673ZM7.00898 12.6673C8.58121 12.6673 9.91732 12.1145 11.0173 11.009C12.1173 9.90343 12.6673 8.56454 12.6673 6.99232C12.6673 5.4201 12.1184 4.08398 11.0205 2.98398C9.92253 1.88398 8.5826 1.33398 7.00065 1.33398C5.43398 1.33398 4.09787 1.88294 2.99232 2.98085C1.88676 4.07877 1.33398 5.41871 1.33398 7.00065C1.33398 8.56732 1.88676 9.90343 2.99232 11.009C4.09787 12.1145 5.43676 12.6673 7.00898 12.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
