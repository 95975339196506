import React from 'react'

export const IconDownload = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00065 10.4798L2.97982 6.45898L3.87565 5.56315L6.37565 8.06315V0.333984H7.62565V8.06315L10.1257 5.56315L11.0215 6.45898L7.00065 10.4798ZM1.58398 13.6673C1.25065 13.6673 0.958984 13.5423 0.708984 13.2923C0.458984 13.0423 0.333984 12.7507 0.333984 12.4173V9.43815H1.58398V12.4173H12.4173V9.43815H13.6673V12.4173C13.6673 12.7507 13.5423 13.0423 13.2923 13.2923C13.0423 13.5423 12.7507 13.6673 12.4173 13.6673H1.58398Z"
        fill="#161F28"
      />
      <path
        d="M7.00065 10.4798L2.97982 6.45898L3.87565 5.56315L6.37565 8.06315V0.333984H7.62565V8.06315L10.1257 5.56315L11.0215 6.45898L7.00065 10.4798ZM1.58398 13.6673C1.25065 13.6673 0.958984 13.5423 0.708984 13.2923C0.458984 13.0423 0.333984 12.7507 0.333984 12.4173V9.43815H1.58398V12.4173H12.4173V9.43815H13.6673V12.4173C13.6673 12.7507 13.5423 13.0423 13.2923 13.2923C13.0423 13.5423 12.7507 13.6673 12.4173 13.6673H1.58398Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 10.4798L2.97982 6.45898L3.87565 5.56315L6.37565 8.06315V0.333984H7.62565V8.06315L10.1257 5.56315L11.0215 6.45898L7.00065 10.4798ZM1.58398 13.6673C1.25065 13.6673 0.958984 13.5423 0.708984 13.2923C0.458984 13.0423 0.333984 12.7507 0.333984 12.4173V9.43815H1.58398V12.4173H12.4173V9.43815H13.6673V12.4173C13.6673 12.7507 13.5423 13.0423 13.2923 13.2923C13.0423 13.5423 12.7507 13.6673 12.4173 13.6673H1.58398Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 10.4798L2.97982 6.45898L3.87565 5.56315L6.37565 8.06315V0.333984H7.62565V8.06315L10.1257 5.56315L11.0215 6.45898L7.00065 10.4798ZM1.58398 13.6673C1.25065 13.6673 0.958984 13.5423 0.708984 13.2923C0.458984 13.0423 0.333984 12.7507 0.333984 12.4173V9.43815H1.58398V12.4173H12.4173V9.43815H13.6673V12.4173C13.6673 12.7507 13.5423 13.0423 13.2923 13.2923C13.0423 13.5423 12.7507 13.6673 12.4173 13.6673H1.58398Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 10.4798L2.97982 6.45898L3.87565 5.56315L6.37565 8.06315V0.333984H7.62565V8.06315L10.1257 5.56315L11.0215 6.45898L7.00065 10.4798ZM1.58398 13.6673C1.25065 13.6673 0.958984 13.5423 0.708984 13.2923C0.458984 13.0423 0.333984 12.7507 0.333984 12.4173V9.43815H1.58398V12.4173H12.4173V9.43815H13.6673V12.4173C13.6673 12.7507 13.5423 13.0423 13.2923 13.2923C13.0423 13.5423 12.7507 13.6673 12.4173 13.6673H1.58398Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
