import { Checkbox, FormControlLabel, CheckboxProps } from '@mui/material'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

export function CheckboxRHF<FormType extends FieldValues>({
  name,
  rules,
  control,
  ...checkboxProps
}: {
  label: string
  name: string
} & CheckboxProps &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={checkboxProps?.defaultValue || (false as any)}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!value}
              value={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={
            <span
              style={{
                fontFamily: 'Roboto',
                color: '#161F28',
                fontSize: '16px',
                fontWeight: 500,
                marginLeft: '3px',
              }}
            >
              {checkboxProps?.label}
            </span>
          }
        />
      )}
    />
  )
}
