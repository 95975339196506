import React from 'react'

interface IProps {
  width?: string
  height?: string
  color?: string
}

export const IconUploadArchive = ({
  width = '33',
  height = '40',
  color = '#6D42E1',
}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1 33.9H18.1V23.85L22.2 27.95L24.3 25.85L16.5 18.25L8.8 25.95L10.9 28.05L15.1 23.85V33.9ZM3.5 40C2.7 40 2 39.7 1.4 39.1C0.8 38.5 0.5 37.8 0.5 37V3C0.5 2.2 0.8 1.5 1.4 0.9C2 0.3 2.7 0 3.5 0H21.55L32.5 10.95V37C32.5 37.8 32.2 38.5 31.6 39.1C31 39.7 30.3 40 29.5 40H3.5ZM20.05 12.3V3H3.5V37H29.5V12.3H20.05Z"
        fill={color}
      />
    </svg>
  )
}
