import React from 'react'

export const IconArrowDown = () => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99935 4.00026L0.666016 0.683594H7.33268L3.99935 4.00026Z"
        fill="#161F28"
      />
      <path
        d="M3.99935 4.00026L0.666016 0.683594H7.33268L3.99935 4.00026Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M3.99935 4.00026L0.666016 0.683594H7.33268L3.99935 4.00026Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M3.99935 4.00026L0.666016 0.683594H7.33268L3.99935 4.00026Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M3.99935 4.00026L0.666016 0.683594H7.33268L3.99935 4.00026Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
