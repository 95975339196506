import React from 'react'

interface IIconProps {
  color?: string
}

export const IconUpload = ({ color }: IIconProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4375 9.13125V2.175L3.1875 4.425L2.38125 3.61875L6 0L9.61875 3.61875L8.8125 4.425L6.5625 2.175V9.13125H5.4375ZM1.125 12C0.825 12 0.5625 11.8875 0.3375 11.6625C0.1125 11.4375 0 11.175 0 10.875V8.19375H1.125V10.875H10.875V8.19375H12V10.875C12 11.175 11.8875 11.4375 11.6625 11.6625C11.4375 11.8875 11.175 12 10.875 12H1.125Z"
        fill={color ?? '#59636B'}
      />
    </svg>
  )
}
