import React from 'react'

export const IconTrash = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.43815 15.5C2.0944 15.5 1.80013 15.3776 1.55534 15.1328C1.31055 14.888 1.18815 14.5938 1.18815 14.25V2.375H0.333984V1.125H4.25065V0.5H9.75065V1.125H13.6673V2.375H12.8132V14.25C12.8132 14.5833 12.6882 14.875 12.4382 15.125C12.1882 15.375 11.8965 15.5 11.5632 15.5H2.43815ZM11.5632 2.375H2.43815V14.25H11.5632V2.375ZM4.64648 12.4583H5.89648V4.14583H4.64648V12.4583ZM8.10482 12.4583H9.35482V4.14583H8.10482V12.4583Z"
        fill="#161F28"
      />
      <path
        d="M2.43815 15.5C2.0944 15.5 1.80013 15.3776 1.55534 15.1328C1.31055 14.888 1.18815 14.5938 1.18815 14.25V2.375H0.333984V1.125H4.25065V0.5H9.75065V1.125H13.6673V2.375H12.8132V14.25C12.8132 14.5833 12.6882 14.875 12.4382 15.125C12.1882 15.375 11.8965 15.5 11.5632 15.5H2.43815ZM11.5632 2.375H2.43815V14.25H11.5632V2.375ZM4.64648 12.4583H5.89648V4.14583H4.64648V12.4583ZM8.10482 12.4583H9.35482V4.14583H8.10482V12.4583Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M2.43815 15.5C2.0944 15.5 1.80013 15.3776 1.55534 15.1328C1.31055 14.888 1.18815 14.5938 1.18815 14.25V2.375H0.333984V1.125H4.25065V0.5H9.75065V1.125H13.6673V2.375H12.8132V14.25C12.8132 14.5833 12.6882 14.875 12.4382 15.125C12.1882 15.375 11.8965 15.5 11.5632 15.5H2.43815ZM11.5632 2.375H2.43815V14.25H11.5632V2.375ZM4.64648 12.4583H5.89648V4.14583H4.64648V12.4583ZM8.10482 12.4583H9.35482V4.14583H8.10482V12.4583Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M2.43815 15.5C2.0944 15.5 1.80013 15.3776 1.55534 15.1328C1.31055 14.888 1.18815 14.5938 1.18815 14.25V2.375H0.333984V1.125H4.25065V0.5H9.75065V1.125H13.6673V2.375H12.8132V14.25C12.8132 14.5833 12.6882 14.875 12.4382 15.125C12.1882 15.375 11.8965 15.5 11.5632 15.5H2.43815ZM11.5632 2.375H2.43815V14.25H11.5632V2.375ZM4.64648 12.4583H5.89648V4.14583H4.64648V12.4583ZM8.10482 12.4583H9.35482V4.14583H8.10482V12.4583Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M2.43815 15.5C2.0944 15.5 1.80013 15.3776 1.55534 15.1328C1.31055 14.888 1.18815 14.5938 1.18815 14.25V2.375H0.333984V1.125H4.25065V0.5H9.75065V1.125H13.6673V2.375H12.8132V14.25C12.8132 14.5833 12.6882 14.875 12.4382 15.125C12.1882 15.375 11.8965 15.5 11.5632 15.5H2.43815ZM11.5632 2.375H2.43815V14.25H11.5632V2.375ZM4.64648 12.4583H5.89648V4.14583H4.64648V12.4583ZM8.10482 12.4583H9.35482V4.14583H8.10482V12.4583Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
