import React from 'react'

import { ButtonBack } from '@intraversa-lab/styleguide'
import { IconNotification } from 'components/Notification/Notification'
import { navigateToUrl } from 'single-spa'

export const HeaderMain = () => {
  const onBack = () => {
    navigateToUrl('/')
  }

  return (
    <div className="flex align-center gap-4 items-center">
      <ButtonBack onClick={onBack} />
      {/* <IconNotification /> */}
    </div>
  )
}
