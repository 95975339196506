import React, { useState } from 'react'

import { IconArrowDown } from 'assets/IconArrowDown'
import { IconArrowTop } from 'assets/IconArrowTop'
import { IconInfo } from 'assets/IconInfo'

interface IProps {
  description: string
}

export const ButtonInfo = ({ description }: IProps) => {
  const [showDrop, setShowDrop] = useState(false)

  return (
    <div>
      <div
        onClick={() => setShowDrop(!showDrop)}
        className="rounded-[16px] border border-gray-300 relative"
        style={{ width: 'fit-content', padding: '5.50px' }}
      >
        <button className="flex items-center" style={{ gap: '6px' }}>
          <IconInfo />

          {showDrop ? <IconArrowTop /> : <IconArrowDown />}
        </button>
      </div>

      {showDrop && (
        <div className="bg-white text-left z-[100] max-w-[180px] mt-2 rounded-[4px] py-[12px] px-[16px] absolute shadow-md text-xs">
          <p>{description}</p>
        </div>
      )}

      {showDrop && (
        <div
          onClick={() => setShowDrop(false)}
          id="overlay-button"
          className="fixed top-0 right-0 left-0 bottom-0"
        ></div>
      )}
    </div>
  )
}
