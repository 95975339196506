/* eslint-disable no-labels */
/* eslint-disable no-unused-expressions */

import { ButtonInfo } from 'components/ButtonInfo/ButtonInfo'

export const renderCellColumn = {
  status: (_, { statusCodeApi, responseApi }) => {
    return statusCodeApi === 500 ? (
      <div className="flex justify-center">
        <ButtonInfo description={responseApi} />
      </div>
    ) : (
      <span>-</span>
    )
  },
}
