/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal } from '@intraversa-lab/styleguide';
import { CheckboxRHF } from 'components/FormRHF/CheckboxRHF';
import { SelectRHF } from 'components/FormRHF/InputFieldRHF copy';
import { TextFieldRHF } from 'components/FormRHF/TextFieldRHF';
import { queryClient } from 'configs/queryClient';
import { IPayloadUnitaryRenewal } from 'domains/ActionConfig/actionTypes';
import { usePostAction } from 'domains/ActionConfig/useCases/usePostAction';
import { useGetPlans, useGetRecipients } from 'domains/PagarMe';
import { QueryKeys } from 'infra';
import { FormProvider, useForm } from 'react-hook-form';

import { unitaryCancellationSchema, unitarySchema } from './UnitarySchema';

interface IModalUnitaryRenawelProps {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  title: string;
}

interface dataPayload {
  assinatura: number;
  novaAssinatura: boolean;
  novoPlano: number;
  nome: string;
  email: string;
  tipoConta: string;
}

export const ModalUnitaryRenawel: React.FC<IModalUnitaryRenawelProps> = ({
  isOpen,
  onClose,
  type,
  title
}) => {
  const { data: plans } = useGetPlans();
  const { data: recipients } = useGetRecipients();

  const isCancellation = type === 'CANCELLATION';

  const methods = useForm<dataPayload>({
    resolver: zodResolver(
      isCancellation ? unitaryCancellationSchema : unitarySchema
    ),
    defaultValues: isCancellation
      ? {
          assinatura: null,
          nome: '',
          email: ''
        }
      : {
          assinatura: null,
          novaAssinatura: false,
          novoPlano: null,
          nome: '',
          email: '',
          tipoConta: ''
        },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    formState: { errors }
  } = methods;

  const { postUnitaryRenewal } = usePostAction({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      onClose();
    }
  });

  function onSubmit(data: dataPayload) {
    const result: IPayloadUnitaryRenewal = {
      recipientId: undefined,
      newSubscription: undefined,
      clientEmail: data.email,
      clientName: data.nome,
      newPlanId: undefined,
      subscription: data.assinatura
    };

    if (!isCancellation) {
      result.recipientId = data.tipoConta;
      result.newSubscription = data.novaAssinatura;
      result.newPlanId = data.novoPlano;
    }

    postUnitaryRenewal(result);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}
      >
        <div className="z-10">
          <div className="space-y-4 mb-8">
            <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
              {isCancellation ? 'Cancelamento Unitário' : 'Renovação Unitária'}
            </h1>
            <h3 className="text-[#75808A] text-base">
              Preencha os campos abaixo para adicionar uma renovação de forma
              unitária à tabela.
            </h3>
          </div>

          <FormProvider {...methods}>
            <div className="space-y-4 mb-8">
              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Assinatura</label>
                <div className="flex gap-3 items-center">
                  <div className="">
                    <TextFieldRHF
                      mask="number"
                      name="assinatura"
                      helperText
                      placeholder="Assinatura"
                    />
                    {errors.assinatura && (
                      <p className="text-xs text-error">Campo Obrigatório</p>
                    )}
                  </div>

                  {!isCancellation && (
                    <CheckboxRHF
                      label="Nova assinatura"
                      id="novaAssinatura"
                      name="novaAssinatura"
                    />
                  )}
                </div>
              </div>

              {!isCancellation && (
                <div className="flex items-center">
                  <label className="w-[120px] mr-6 font-medium">
                    Novo plano
                  </label>
                  <div className="flex-1">
                    <SelectRHF
                      options={
                        plans?.map(item => ({
                          label: String(item.name),
                          value: String(item.id)
                        })) || []
                      }
                      name="novoPlano"
                      placeholder="Selecionar novo Plano"
                      control={methods.control}
                    />
                    {errors.novoPlano && (
                      <p className="text-xs text-error">Campo Obrigatório</p>
                    )}
                  </div>
                </div>
              )}

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Nome</label>
                <div className="flex-1">
                  <TextFieldRHF name="nome" placeholder="Nome" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Email</label>
                <div className="flex-1">
                  <TextFieldRHF name="email" placeholder="Emal" />
                </div>
              </div>

              {!isCancellation && (
                <div className="flex items-center">
                  <label className="w-[120px] mr-6 font-medium">
                    Tipo de Conta
                  </label>
                  <div className="flex-1">
                    <SelectRHF
                      options={
                        recipients?.map(item => ({
                          label: String(item.bank_account.legal_name),
                          value: String(item.id)
                        })) || []
                      }
                      name="tipoConta"
                      placeholder="Selecionar tipo de conta"
                      control={methods.control}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center space-x-4">
              <Button
                variant="outlined"
                className="flex-1"
                onClick={onClose}
                style={{ textTransform: 'none' }}
              >
                Cancelar
              </Button>
              <Button
                className="flex-1"
                type="submit"
                onClick={() => handleSubmit(onSubmit)()}
                style={{ textTransform: 'none' }}
              >
                Adicionar
              </Button>
            </div>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
