/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@intraversa-lab/styleguide'

import { flowApi } from './flowApi'
import { IResponseFlow } from './flowTypes'

async function getRpaFlow(props: {
  page: number
  offset: number
  sort?: string
  status?: string
}): Promise<IResponseFlow> {
  try {
    const respose = await flowApi.getRpaFlow(props)

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar RPAs.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function postInit(): Promise<any> {
  try {
    const response = await flowApi.postInit()

    return response
  } catch (error) {
    throw new Error('Tente novamente em instantes.')
  }
}

export const flowService = {
  getRpaFlow,
  postInit,
}
