export const IconArrowTop = () => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666016 4.33242L3.99935 0.982422L7.33268 4.33242H0.666016Z"
        fill="#6D42E1"
      />
    </svg>
  )
}
