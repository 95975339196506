import React from 'react'

interface IProps {
  size?: string
  color?: string
}

export const IconClose = ({ size = '12', color = '#59636B' }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66836 11.1184L0.880859 10.3309L5.21211 5.99961L0.880859 1.66836L1.66836 0.880859L5.99961 5.21211L10.3309 0.880859L11.1184 1.66836L6.78711 5.99961L11.1184 10.3309L10.3309 11.1184L5.99961 6.78711L1.66836 11.1184Z"
        fill={color}
      />
    </svg>
  )
}
