// import { Select } from '@mui/material';
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form';

import { IInputProps } from './InputField/InputField';
import { InputSelect } from './InputSelect';

export type TProps = { label: string; value: string };

export function InputSelectdRHF<FormType extends FieldValues>({
  isError,
  options,
  name,
  rules,
  control,
  ...rest
}: {
  options: TProps[];
  name: string;
} & IInputProps &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <InputSelect
          {...rest}
          options={options}
          value={options.find(item => String(item.value) === String(value))}
          onChange={(value: TProps) => onChange(value.value)}
          isError={isError}
        />
      )}
    />
  );
}
