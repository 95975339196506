import React from 'react'

export const IconClock = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9848 15.0826C6.18031 15.0826 4.60766 14.4886 3.26686 13.3006C1.92605 12.1126 1.15416 10.6232 0.951172 8.83266H2.22678C2.43725 10.2718 3.08495 11.4649 4.16988 12.412C5.25482 13.3591 6.52646 13.8327 7.9848 13.8327C9.6098 13.8327 10.9883 13.2667 12.1202 12.1347C13.2522 11.0028 13.8181 9.62433 13.8181 7.99933C13.8181 6.37433 13.2522 4.99586 12.1202 3.86391C10.9883 2.73197 9.6098 2.166 7.9848 2.166C7.07455 2.166 6.22145 2.36818 5.42551 2.77256C4.62957 3.17693 3.94421 3.73329 3.36942 4.44162H5.5489V5.6916H1.31819V1.46087H2.56815V3.43525C3.24443 2.63718 4.05425 2.01779 4.99763 1.57708C5.941 1.13637 6.93673 0.916016 7.9848 0.916016C8.9677 0.916016 9.88864 1.10192 10.7476 1.47372C11.6066 1.84552 12.3555 2.35085 12.9944 2.98972C13.6333 3.62861 14.1386 4.37754 14.5104 5.23652C14.8822 6.09549 15.0681 7.01643 15.0681 7.99933C15.0681 8.98223 14.8822 9.90317 14.5104 10.7621C14.1386 11.6211 13.6333 12.37 12.9944 13.0089C12.3555 13.6478 11.6066 14.1531 10.7476 14.5249C9.88864 14.8967 8.9677 15.0826 7.9848 15.0826ZM10.4864 11.3551L7.38384 8.25254V3.83266H8.63382V7.74612L11.3646 10.4769L10.4864 11.3551Z"
        fill="#75808A"
      />
    </svg>
  )
}
