/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal } from '@intraversa-lab/styleguide';
import { CheckboxRHF } from 'components/FormRHF/CheckboxRHF';
import { TextFieldRHF } from 'components/FormRHF/TextFieldRHF';
import { queryClient } from 'configs/queryClient';
import { QueryKeys } from 'infra';
import { FormProvider, useForm } from 'react-hook-form';

import { unitaryCancellationSchema, unitarySchema } from './UnitarySchema';
import { usePostLive } from 'domains/ActionConfig/useCases/usePostLive';
import { IPayloadLiveRenewal } from 'domains/ActionConfig/actionTypes';

interface IModalUnitaryRenawelProps {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  title: string;
}

interface dataPayload {
  assinatura: number;
  novaAssinatura: boolean;
  novoPlano: number;
  nome: string;
  email: string;
  tipoConta: string;
}

interface livePayload {
  name: string;
  isPlaylist: boolean;
  initCellLetter: string;
  initCellNumber: number;
  initHeaderCellLetter: string;
  initHeaderCellNumber: number;
  playlistId: string;
  minutesInterval: number;
  liveHourTime: number;
  sheetBase: string;
  descriptionLive: string;
  platform: string;
  spreadsheetId: string;
}

export const ModalNewLive: React.FC<IModalUnitaryRenawelProps> = ({
  isOpen,
  onClose,
  type,
  title
}) => {
  const isCancellation = type === 'CANCELLATION';

  const methods = useForm<livePayload>({
    resolver: zodResolver(
      isCancellation ? unitaryCancellationSchema : unitarySchema
    ),
    defaultValues: {
      name: '',
      initCellLetter: 'B',
      initCellNumber: 0,
      initHeaderCellLetter: 'B',
      initHeaderCellNumber: 0,
      playlistId: '',
      minutesInterval: 0,
      liveHourTime: 0,
      sheetBase: '',
      descriptionLive: '',
      platform: 'YOUTUBE',
      spreadsheetId: '1XboAlwWSaoqSw_WXeGdvn9-2eh_hLSK5mHOFlJOWmiU'
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    formState: { errors }
  } = methods;

  const { postUnitaryLive } = usePostLive({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      onClose();
    }
  });

  function onSubmit(data: livePayload) {
    const result: IPayloadLiveRenewal = {
      name: data.name,
      isPlaylist: data.isPlaylist,
      platform: 'YOUTUBE',
      spreadsheetId: '1XboAlwWSaoqSw_WXeGdvn9-2eh_hLSK5mHOFlJOWmiU',
      initCellLetter: data.initCellLetter,
      initCellNumber: data.initCellNumber,
      initHeaderCellLetter: data.initHeaderCellLetter,
      initHeaderCellNumber: data.initHeaderCellNumber,
      playlistId: data.playlistId,
      sheetBase: data.sheetBase,
      minutesInterval: data.minutesInterval,
      liveHourTime: data.liveHourTime,
      descriptionLive: data.name
    };
    console.log(result);
    postUnitaryLive(result);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}
      >
        <div className="z-10">
          <div className="space-y-4 mb-8">
            <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
              Adicionar Live
            </h1>
            <h3 className="text-[#75808A] text-base">
              Preencha os campos abaixo para adicionar o acompanhamento de uma
              live.
            </h3>
          </div>

          <FormProvider {...methods}>
            <div className="space-y-4 mb-8">
              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Live ID / Video ID
                </label>
                <div className="flex gap-3 items-center">
                  <div className="">
                    <TextFieldRHF
                      name="playlistId"
                      helperText
                      placeholder="Live ID ou Video ID"
                    />
                    {errors.playlistId && (
                      <p className="text-xs text-error">Campo Obrigatório</p>
                    )}
                  </div>

                  {!isCancellation && (
                    <CheckboxRHF
                      label="É Playlist?"
                      id="isPlaylist"
                      name="isPlaylist"
                    />
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Nome</label>
                <div className="flex-1">
                  <TextFieldRHF name="name" placeholder="Nome" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Tab Base</label>
                <div className="flex-1">
                  <TextFieldRHF name="sheetBase" placeholder="Tab Base" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Peridiocidade em minutos
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    mask="number"
                    name="minutesInterval"
                    placeholder="Peridiocidade em minutos"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Tempo médio da live em horas
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    mask="number"
                    name="liveHourTime"
                    placeholder="Tempo médio da live"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Célula letra inicial horário
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    name="initHeaderCellLetter"
                    placeholder="Célula letra inicial horário"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Célula número inicial horário
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    mask="number"
                    name="initHeaderCellNumber"
                    placeholder="Célula número inicial horário"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Célula letra inicial valor
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    name="initCellLetter"
                    placeholder="Célula letra inicial Valor"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">
                  Célula número inicial valor
                </label>
                <div className="flex-1">
                  <TextFieldRHF
                    mask="number"
                    name="initCellNumber"
                    placeholder="Célula número inicial valor"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Button
                variant="outlined"
                className="flex-1"
                onClick={onClose}
                style={{ textTransform: 'none' }}
              >
                Cancelar
              </Button>
              <Button
                className="flex-1"
                type="submit"
                onClick={() => handleSubmit(onSubmit)()}
                style={{ textTransform: 'none' }}
              >
                Adicionar
              </Button>
            </div>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
