/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { UploaderTab } from 'types/UploaderConfig'

export const formatColumns: Record<
  UploaderTab['columns'][0]['format'],
  (value: any) => any
> = {
  date: (value) =>
    !!value && value !== null
      ? moment(value).format('DD/MM/YYYY HH:mm:ss')
      : '-',
  boolean: (value) => (value ? 'Sim' : 'Não'),
  checkEmpty: (value) => value ?? '-',
  monetary: (value) => value,
  number: (value) => value,
  status: (value) => value,
}
