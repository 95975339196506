/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@intraversa-lab/styleguide'
import { MutationOptions } from 'infra'
import { useMutation } from 'react-query'

import { actionService } from '../actionService'

export function useGetModelCSV(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, any>({
    mutationFn: () => actionService.getModelCSV(),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        text: 'Download de modelo CSV realizado',
        description:
          'O download do arquivo modelo CSV foi realizado com sucesso.',
      })
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    onGetModelCSV: () => mutation.mutate({}),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
