import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #dfe4e9;
  border-radius: 4px;
  background: #ffffff;
`

export const CollapseContainer = styled.div`
  margin: 10px 0;
`

export const TextLg = styled.p`
  margin: 0;
  padding: 0;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`

export const TextDescription = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #75808a;
`

export const WrapperInput = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 200px 1fr;

  > .labelInput {
    min-width: max-content;
    margin-top: 18px;
    color: #161f28 !important;
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .middle-button {
    margin-left: 16px;
  }
`

export const CollapseHeader = styled.div`
  cursor: pointer;
  padding: 10px;

  max-height: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px 0px;
  margin-bottom: 24px;
`

export const CollapseContent = styled.div`
  padding: 0px 24px 32px;
  border-top: none;

  ${WrapperInput} + ${WrapperInput} {
    margin-top: 16px;
  }
`

export const IconButton = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 10px;

  width: 44px;
  height: 44px;

  border: 1px solid #bec9d2;
`

export const TextFile = styled.p`
  margin: 0;
  padding: 0;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.001em;
  text-align: left;
  color: #c8d0d9;
`
