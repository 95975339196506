import { IconArrowRight } from 'assets/IconArrowRight'
import {
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes'

import * as S from './styles'

interface ICardChooseConfig {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES
  onSelected: (name: string) => void
}
export const CardChooseConfig = ({ type, onSelected }: ICardChooseConfig) => {
  const configSelected = schedulerConfigTypes.find(
    (config) => config.type === type,
  )

  return (
    <S.Container onClick={() => onSelected(type)}>
      <div className="Container__Texts">
        <h3>{configSelected.title}</h3>
        <p>Ultima edição 20/11/2023 09:34:01</p>
      </div>

      <IconArrowRight />
    </S.Container>
  )
}
