import React from 'react'

export const Countdown = ({ start, setCountComplete }) => {
  const [secondsLeft, setSecondsLeft] = React.useState(0)
  const [initial, setInitial] = React.useState(true)

  React.useEffect(() => {
    if (secondsLeft <= 0 && initial) {
      setInitial(false)
    }

    if (secondsLeft <= 0 && !initial) {
      setCountComplete(true)
      setInitial(true)
    }

    const timeout = setTimeout(() => {
      setSecondsLeft(secondsLeft - 1)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [secondsLeft])

  React.useEffect(() => {
    setSecondsLeft(start)
  }, [start])

  return <>{secondsLeft}</>
}
