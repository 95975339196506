import React from 'react'

export const IconUpdate = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00065 13.6673C5.15343 13.6673 3.58051 13.018 2.2819 11.7194C0.98329 10.4208 0.333984 8.84787 0.333984 7.00065C0.333984 5.15343 0.98329 3.58051 2.2819 2.2819C3.58051 0.98329 5.15343 0.333984 7.00065 0.333984C8.18121 0.333984 9.21593 0.573568 10.1048 1.05273C10.9937 1.5319 11.7645 2.18815 12.4173 3.02148V0.333984H13.6673V5.62565H8.37565V4.37565H11.8757C11.3479 3.54232 10.6743 2.86871 9.85482 2.35482C9.03537 1.84093 8.08398 1.58398 7.00065 1.58398C5.48676 1.58398 4.20551 2.10829 3.1569 3.1569C2.10829 4.20551 1.58398 5.48676 1.58398 7.00065C1.58398 8.51454 2.10829 9.79579 3.1569 10.8444C4.20551 11.893 5.48676 12.4173 7.00065 12.4173C8.15343 12.4173 9.20898 12.0875 10.1673 11.4277C11.1257 10.768 11.7923 9.89648 12.1673 8.81315H13.459C13.0562 10.2715 12.2576 11.4451 11.0632 12.334C9.86871 13.2229 8.51454 13.6673 7.00065 13.6673Z"
        fill="#161F28"
      />
      <path
        d="M7.00065 13.6673C5.15343 13.6673 3.58051 13.018 2.2819 11.7194C0.98329 10.4208 0.333984 8.84787 0.333984 7.00065C0.333984 5.15343 0.98329 3.58051 2.2819 2.2819C3.58051 0.98329 5.15343 0.333984 7.00065 0.333984C8.18121 0.333984 9.21593 0.573568 10.1048 1.05273C10.9937 1.5319 11.7645 2.18815 12.4173 3.02148V0.333984H13.6673V5.62565H8.37565V4.37565H11.8757C11.3479 3.54232 10.6743 2.86871 9.85482 2.35482C9.03537 1.84093 8.08398 1.58398 7.00065 1.58398C5.48676 1.58398 4.20551 2.10829 3.1569 3.1569C2.10829 4.20551 1.58398 5.48676 1.58398 7.00065C1.58398 8.51454 2.10829 9.79579 3.1569 10.8444C4.20551 11.893 5.48676 12.4173 7.00065 12.4173C8.15343 12.4173 9.20898 12.0875 10.1673 11.4277C11.1257 10.768 11.7923 9.89648 12.1673 8.81315H13.459C13.0562 10.2715 12.2576 11.4451 11.0632 12.334C9.86871 13.2229 8.51454 13.6673 7.00065 13.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 13.6673C5.15343 13.6673 3.58051 13.018 2.2819 11.7194C0.98329 10.4208 0.333984 8.84787 0.333984 7.00065C0.333984 5.15343 0.98329 3.58051 2.2819 2.2819C3.58051 0.98329 5.15343 0.333984 7.00065 0.333984C8.18121 0.333984 9.21593 0.573568 10.1048 1.05273C10.9937 1.5319 11.7645 2.18815 12.4173 3.02148V0.333984H13.6673V5.62565H8.37565V4.37565H11.8757C11.3479 3.54232 10.6743 2.86871 9.85482 2.35482C9.03537 1.84093 8.08398 1.58398 7.00065 1.58398C5.48676 1.58398 4.20551 2.10829 3.1569 3.1569C2.10829 4.20551 1.58398 5.48676 1.58398 7.00065C1.58398 8.51454 2.10829 9.79579 3.1569 10.8444C4.20551 11.893 5.48676 12.4173 7.00065 12.4173C8.15343 12.4173 9.20898 12.0875 10.1673 11.4277C11.1257 10.768 11.7923 9.89648 12.1673 8.81315H13.459C13.0562 10.2715 12.2576 11.4451 11.0632 12.334C9.86871 13.2229 8.51454 13.6673 7.00065 13.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 13.6673C5.15343 13.6673 3.58051 13.018 2.2819 11.7194C0.98329 10.4208 0.333984 8.84787 0.333984 7.00065C0.333984 5.15343 0.98329 3.58051 2.2819 2.2819C3.58051 0.98329 5.15343 0.333984 7.00065 0.333984C8.18121 0.333984 9.21593 0.573568 10.1048 1.05273C10.9937 1.5319 11.7645 2.18815 12.4173 3.02148V0.333984H13.6673V5.62565H8.37565V4.37565H11.8757C11.3479 3.54232 10.6743 2.86871 9.85482 2.35482C9.03537 1.84093 8.08398 1.58398 7.00065 1.58398C5.48676 1.58398 4.20551 2.10829 3.1569 3.1569C2.10829 4.20551 1.58398 5.48676 1.58398 7.00065C1.58398 8.51454 2.10829 9.79579 3.1569 10.8444C4.20551 11.893 5.48676 12.4173 7.00065 12.4173C8.15343 12.4173 9.20898 12.0875 10.1673 11.4277C11.1257 10.768 11.7923 9.89648 12.1673 8.81315H13.459C13.0562 10.2715 12.2576 11.4451 11.0632 12.334C9.86871 13.2229 8.51454 13.6673 7.00065 13.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.00065 13.6673C5.15343 13.6673 3.58051 13.018 2.2819 11.7194C0.98329 10.4208 0.333984 8.84787 0.333984 7.00065C0.333984 5.15343 0.98329 3.58051 2.2819 2.2819C3.58051 0.98329 5.15343 0.333984 7.00065 0.333984C8.18121 0.333984 9.21593 0.573568 10.1048 1.05273C10.9937 1.5319 11.7645 2.18815 12.4173 3.02148V0.333984H13.6673V5.62565H8.37565V4.37565H11.8757C11.3479 3.54232 10.6743 2.86871 9.85482 2.35482C9.03537 1.84093 8.08398 1.58398 7.00065 1.58398C5.48676 1.58398 4.20551 2.10829 3.1569 3.1569C2.10829 4.20551 1.58398 5.48676 1.58398 7.00065C1.58398 8.51454 2.10829 9.79579 3.1569 10.8444C4.20551 11.893 5.48676 12.4173 7.00065 12.4173C8.15343 12.4173 9.20898 12.0875 10.1673 11.4277C11.1257 10.768 11.7923 9.89648 12.1673 8.81315H13.459C13.0562 10.2715 12.2576 11.4451 11.0632 12.334C9.86871 13.2229 8.51454 13.6673 7.00065 13.6673Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}
