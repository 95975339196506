/* eslint-disable @typescript-eslint/no-explicit-any */
export const downloadDocumentNavigator = (
  data,
  fileName = 'model-csv',
  isBase64 = true,
) => {
  if (data) {
    const csvData = isBase64 ? atob(data) : data

    const csvBlob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;',
    })

    const csvFile = new File([csvBlob], fileName, {
      type: 'text/csv;charset=utf-8;',
    })

    const url = URL.createObjectURL(csvFile)

    const link = document.createElement('a')
    link.href = url

    link.download = fileName + '.csv'

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }
}
